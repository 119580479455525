import {useEffect} from "react";
import $ from "jquery";

export const Navigation = (props) => {
  useEffect(() => {
    $('.nav a').on('click', function () {
      const viewportWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
      if(viewportWidth < 1200)
        $('.navbar-toggle').click(); //bootstrap 3.x  
    });
  }, [])
  return (
    <nav id='menu' className='navbar navbar-default navbar-fixed-top'>
      <div className='container'>
        <div className='navbar-header'>
          <button
            type='button'
            className='navbar-toggle collapsed'
            data-toggle='collapse'
            data-target='#bs-example-navbar-collapse-1'
          >
            {' '}
            <span className='sr-only'>Toggle navigation</span>{' '}
            <span className='icon-bar'></span>{' '}
            <span className='icon-bar'></span>{' '}
            <span className='icon-bar'></span>{' '}
          </button>
          <div className='title-logo'>
            <img className='navbar-logo' alt='Trust Manpower Support Services' src='img/logo.png' />
            <a className='navbar-brand page-scroll' href='#page-top'>
              TRUST MANPOWER SUPPORT SERVICES
            </a>
          </div>
          {' '}
        </div>

        <div
          className='collapse navbar-collapse'
          id='bs-example-navbar-collapse-1'
        >
          <ul className='nav navbar-nav navbar-right'>
            <li>
              <a href='#about' className='page-scroll'>
                About Us
              </a>
            </li>
            <li>
              <a href='#principles' className='page-scroll'>
                Mission & Vision 
              </a>
            </li>
            <li>
              <a href='#why' className='page-scroll'>
                Why Choose Us?
              </a>
            </li>
            <li>
              <a href='#services' className='page-scroll'>
                Services
              </a>
            </li>
            <li>
              <a href='#customer-segments' className='page-scroll'>
                Customer Segments
              </a>
            </li>
            <li>
              <a href='#contact' className='page-scroll'>
                Contact Us
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}

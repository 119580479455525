export const About = (props) => {

  const paragraphTextArray = (props.data)? props.data.paragraph.split("\n"): 'loading...'
  
  return (
    <div id='about'>
      <div className='container'>
        <div className='row'>
          <div className='col-xs-12 col-md-6'>
            {' '}
            <img src='img/logo.png' className='img-responsive' alt='TRUST MANPOWER SUPPORT SERVICES' />{' '}
          </div>
          <div className='col-xs-12 col-md-6'>
            <div className='about-text'>
              <h2>About Us</h2>
              {
                (Array.isArray(paragraphTextArray))?
                paragraphTextArray.map((item, i) => {
                  return <p key={i}>{item}</p>
                }):'loading...'
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export const Why = (props) => {
    return (
      <div id='why'>
        <div className='container'>
          <div className='row'>
            <div className='col-xs-12 col-md-6'>
              {' '}
              <img src='img/why-choose-us.jpg' className='img-responsive' alt='Why Choose Us?' />{' '}
            </div>
            <div className='col-xs-12 col-md-6'>
              <div className='why-text'>
                <h2>Why Choose Us?</h2>
                <div className='list-style'>
                  <ul>
                    {props.data
                      ? props.data.map((d, i) => (
                          <li key={`${d}-${i}`}>{d}</li>
                        ))
                      : 'loading'}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
  
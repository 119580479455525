export const Principles = (props) => {
  return (
    <div id='principles' className='text-center'>
      <div className='container'>
        <div className='section-title'>
          <h2>Mission & Vision</h2>
        </div>
        <div className='row'>
          {
            props.data
            ? props.data.map((d, i) => (
          
            <div key={i} className='col-md-6 col-sm-6 col-lg-6'>
              <div className="principle">
                
                <img alt={d.title} src={d.icon} />
              
                <h2>{d.title}</h2>
                <p>
                  {d.text}
                </p>
              </div>
            </div>
            ))
          : 'Loading...'
          }
        </div>
      </div>
    </div>
                
  )
}
